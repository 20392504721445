import React, {useEffect, useState} from "react";
import Background from "../../../menu_background.png";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode"

import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import Auth from "../../../Auth";
import {useStompMsgHandler} from "../../../StompMsgHandler";
import { useTranslate } from 'react-polyglot';

const styles = {
  typography: {
    color: "white",
    fontSize: "20px"
  },

  arrow: {
    marginTop: "4px"
  }
};

const Menu = (props) => {
  let _Auth = new Auth();
  let stompMsgHandler = useStompMsgHandler();

  const [redirect, setRedirect] = useState(false);
  const [loggedin, setLoggedin] = useState(_Auth.loggedIn())
  const [isAdmin, setIsAdmin] = useState(false)
  const t = useTranslate();

  useEffect(() => {
    if(loggedin) {
      let decodedToken = jwt_decode(localStorage.getItem("id_token"))
      if (decodedToken.roles == undefined) {
        logout();
      }
      let role = decodedToken.roles[1].authority
      if(role === "ROLE_ADMIN") {
        setIsAdmin(true)
      }
    }
  }, [])

  const handleChange = () => {
    setRedirect(true);
  };

  const logout = () => {
    //stompMsgHandler.STOMPCLIENT.unsubscribe();
    //stompMsgHandler.STOMPCLIENT.disconnect();
    stompMsgHandler.handleLogout();
    _Auth.logout();
    handleChange();
  };

    if (redirect) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div
        className="menu"
        style={{
          background: `url(${Background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <div className="top-bar">
          <IconButton
            component={Link}
            to="/"
          >
            <img className="burger" src="/assets/images/B.svg" alt="" />
          </IconButton>
          <IconButton onClick={ props.crossBtnListener }>
            <img src="/assets/images/Close.svg" alt="" />
          </IconButton>
        </div>

        <div
          className="menu-container"
          style={loggedin ? { height: "75vh" } : { height: "37vh" }}
        >
          <Button component={Link} to="/" classes={{ root: ".menu menu-item" }}>
            <Typography style={styles.typography}>{t("mainmenu.returntohomepage")}</Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          {isAdmin &&
            <Button component={Link} to="/admin-tu5ng8ub34ng96y7n49hnj" classes={{ root: ".menu menu-item" }}>
            <Typography style={styles.typography}>{t("mainmenu.admin")}</Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          }
          <Button
            component={Link}
            to="/howdoesitwork"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>
              {t("mainmenu.aboutbiotail")}
            </Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          {loggedin ? (
            ""
          ) : (
            <Button
              component={Link}
              to="/registration"
              classes={{ root: ".menu menu-item" }}
            >
              <Typography style={styles.typography}>
                {t("mainmenu.registration.user")}
              </Typography>
              <KeyboardArrowRight style={styles.arrow} />
            </Button>
          )}
          {loggedin ? (
            <Button
              component={Link}
              to="/dogregistration"
              classes={{ root: ".menu menu-item" }}
            >
              <Typography style={styles.typography}>{t("mainmenu.registration.dog")}</Typography>
              <KeyboardArrowRight style={styles.arrow} />
            </Button>
          ) : (
            ""
          )}
          {loggedin ? (
            <Button
              component={Link}
              to="/mydogs"
              classes={{ root: ".menu menu-item" }}
            >
              <Typography style={styles.typography}>{t("mainmenu.mydogs")}</Typography>
              <KeyboardArrowRight style={styles.arrow} />
            </Button>
          ) : (
            ""
          )}
          <Button
            component={Link}
            to="/gallery"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>{t("mainmenu.gallery")}</Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          <Button
            component={Link}
            to="/statistics/weight"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>{t("mainmenu.statistics")}</Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          <Button
            component={Link}
            to="/lectures"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>{t("mainmenu.lectures")}</Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          <Button
            component={Link}
            to="/products"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>{t("mainmenu.products")}</Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          {/*<Button
            component={Link}
            to="/statistics"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>Statistikk</Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>*/}
          {loggedin ? (
            <Button
              component={Link}
              to="/mysettings"
              classes={{ root: ".menu menu-item" }}
            >
              <Typography style={styles.typography}>
                {t("mainmenu.mypage")}
              </Typography>
              <KeyboardArrowRight style={styles.arrow} />
            </Button>
          ) : (
            ""
          )}
          <Button
            component={Link}
            to="/fordeler"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>
              {t("mainmenu.benefits")}
            </Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          <Button
            component={Link}
            to="/faq"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>
              {t("mainmenu.faq")}
            </Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          <Button
            component={Link}
            to="/gdpr"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>{t("mainmenu.privacy")}</Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          <Button
            href="https://kongarthur.freshdesk.com"
            target="_blank"
            classes={{ root: ".menu menu-item" }}
          >
            <Typography style={styles.typography}>{t("mainmenu.feedback")}</Typography>
            <KeyboardArrowRight style={styles.arrow} />
          </Button>
          {loggedin ? (
            <Button onClick={logout} classes={{ root: ".menu menu-item" }}>
              <Typography style={styles.typography}>{t("mainmenu.logout")}</Typography>
              <KeyboardArrowRight style={styles.arrow} />
            </Button>
          ) : (
            ""
          )}
          {!loggedin ? (
            <Button
              component={Link}
              to="/login"
              classes={{ root: ".menu menu-item" }}
            >
              <Typography style={styles.typography}>
                {t("mainmenu.login")}
              </Typography>
              <KeyboardArrowRight style={styles.arrow} />
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }


Menu.propTypes = {
  crossBtnListener: PropTypes.func
};

export default Menu;
